/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';
@import './styles/theme';

html,
body {
  height: fit-content;
}
body {
  margin: 0;
  font-family: comforta-regular, Roboto, 'Helvetica Neue', sans-serif;
}

.flex {
  display: flex;
}
/* calendar*/
.fc .fc-daygrid-day-number {
  color: black;
  text-decoration: none;
}

.fc .fc-col-header-cell-cushion {
  color: black;
}
.disabled-cell {
  background-color: #d3d3d385 !important;
  opacity: 60%;
}
.full-width {
  width: 100%;
}
.fc-h-event {
  background-color: var(--kanoma-purple) !important;
  border-color: var(--kanoma-purple) !important;
}
.fc-h-event.halfday {
  background-color: var(--kanoma-yellow) !important;
  border-color: var(--kanoma-yellow) !important;
  color: black;
}
.fc-h-event.halfday > .fc-event-main {
  color: black;
}
.fc-daygrid-day-frame {
  min-height: 50px !important;
}
.fc-toolbar-title {
  color: var(--kanoma-purple);
}
.fc-toolbar-chunk {
  display: flex;
}

h1 {
  color: var(--kanoma-purple);
}

@mixin kano-font($weight: 400) {
  font-family: 'Mulish', sans-serif;
  font-optical-sizing: auto;
  font-weight: $weight;
  font-style: normal;
}

.kano-font {
  @include kano-font();
}

.fc-daygrid-day-number,
.fc-event,
.fc .fc-addEventButton-button,
.fc .fc-today-button {
  @include kano-font();
}

.fc .fc-toolbar-title {
  @include kano-font(500);
}

.fc-toolbar {
  flex-wrap: wrap;
}

.fc-col-header-cell-cushion {
  @include kano-font(800);
}
