@use './typography' as typo;
@use '@angular/material' as mat;

@include mat.core();

:root {
  --kanoma-purple: #6f52ff;
  --kanoma-yellow: #ffd500;
  --kanoma-blue: #11113f;
}

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

// Generated base on http://mcg.mbitson.com/#!?kanomayellow=%23ffd500&themename=mcgtheme
$kano-purple-palette: (
  50: #eeeaff,
  100: #d4cbff,
  200: #b7a9ff,
  300: #9a86ff,
  400: #856cff,
  500: #6f52ff,
  600: #674bff,
  700: #5c41ff,
  800: #5238ff,
  900: #4028ff,
  A100: #ffffff,
  A200: #ffffff,
  A400: #d9d5ff,
  A700: #c2bcff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  ),
);

$kano-yellow-palette: (
  50: #fffae0,
  100: #fff2b3,
  200: #ffea80,
  300: #ffe24d,
  400: #ffdb26,
  500: #ffd500,
  600: #ffd000,
  700: #ffca00,
  800: #ffc400,
  900: #ffba00,
  A100: #ffffff,
  A200: #fffbf2,
  A400: #ffebbf,
  A700: #ffe4a6,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);

$kano-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette($kano-purple-palette),
      accent: mat.define-palette($kano-yellow-palette),
      warn: mat.define-palette(mat.$orange-palette),
    ),
    typography: typo.$kano-typo,
  )
);

@include mat.all-component-themes($kano-theme);
